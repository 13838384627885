import React from 'react';
import css from './Logo.module.css';

const Logo = () => {
  return (
    <h1 className={css.logo}>
      <span className={css.newline}>
        <span className={css.firstletter}>Y</span>
        <span className={css.othercolor1}>◉</span>ur
      </span>
      <span className={css.newline}>
        Ne<span className={css.othercolor2}>w</span>
      </span>
      <span className={css.newline}>ART</span>
    </h1>
  );
};

export default Logo;
