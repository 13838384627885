import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import css from "./ResultPage.module.css";
import Logo from './../Logo/Logo';

const ResultPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { results } = location.state || { results: [] };
  const correctAnswers = results.filter((result) => result.isCorrect).length;
  const totalAnswers = results.length;
  const score = totalAnswers > 0 ? Math.round((correctAnswers / totalAnswers) * 100) : 0;

  const handleImageClick = (imageUrl, description) => {
    navigate("/image", { state: { imageUrl, description } });
  };
  

  return (
    
    <div className={css.container}>
        {/* <Logo /> */}
      <h1 className={css.score}>Your score: {score}%</h1>
      <table className={css.resultsTable}>
        <thead>
          <tr>
            <th>Image</th>
            <th>Description</th>
            <th>Your Answer</th>
            <th>Correct Answer</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result, index) => (
            <tr key={index}>
              <td
                className={result.isCorrect ? css.correct : css.incorrect}
                onClick={() => handleImageClick(result.image.src, result.description)}
              >
                <img src={result.image.src} alt="" className={css.image} />
              </td>
              <td>{result.description}</td>
              <td>{result.answer}</td>
              <td>{result.image.type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ResultPage;
