import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchImages = createAsyncThunk('images/fetchImages', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch('https://www.yournewart.com/api/images');
    if (!response.ok) {
      throw new Error(`Error loading data. Error code: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

