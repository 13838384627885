import css from './HomePage.module.css';
import bgImage from './../images/bg.jpeg';
import { Link } from 'react-router-dom'; 
import Logo from '../Logo/Logo';


const HomePage = () => {
    return (
     <div className={css.container}>
          <div className={css.leftSection}>
                <Logo />
                <div className={css.text}>
                    <p>AI has learned to create paintings.</p>
                    <p>Let's check if you can determine which painting was created by a human and which one was created by AI.</p>
                    <p>We offer you to take a test with 50 questions.</p>
                </div>
                <Link to="/test" className={css.button}>
                    Take The Test
                </Link>
          </div>
          <div className={css.rightSection}>
            <img src={bgImage} alt="" className={css.bgImage} />
          </div>
    </div>

    );
  };
  
  export default HomePage;