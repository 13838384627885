import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import css from './ImagePage.module.css';

const ImagePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { imageUrl, description } = location.state || {};

  return (
    <div className={css.container}>
      {imageUrl ? (
        <>
          <img src={imageUrl} alt="" className={css.image} />
          <h1 className={css.description}>{description}</h1>
          <button onClick={() => navigate(-1)} className={css.button}>
            Back
          </button>
        </>
      ) : (
        <p>No image provided.</p>
      )}
    </div>
  );
};

export default ImagePage;
