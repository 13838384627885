import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  currentIndex: 0,
  status: 'idle', // 'loading', 'succeeded', 'failed'
  error: null,
};

const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setCurrentIndex(state, action) {
      state.currentIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('images/fetchImages/pending', (state) => {
        state.status = 'loading';
      })
      .addCase('images/fetchImages/fulfilled', (state, action) => {
        state.status = 'succeeded';
        state.items = action.payload;
      })
      .addCase('images/fetchImages/rejected', (state, action) => {
        state.status = 'failed';
        state.error = action.payload || 'Unknow API Error';
      });
  },
});

export const { setCurrentIndex } = imagesSlice.actions;
export default imagesSlice.reducer;
