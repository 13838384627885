import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchImages } from '../../redux/images/operations';
import {
  selectImages,
  selectStatus,
  selectError,
} from '../../redux/images/selectors';
import css from './TestPage.module.css';

const TestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const images = useSelector(selectImages);
  const status = useSelector(selectStatus);
  const error = useSelector(selectError);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [shuffledIndexes, setShuffledIndexes] = useState([]);
  const [results, setResults] = useState([]);
  const [borderColor, setBorderColor] = useState('');
  const [imageInfo, setImageInfo] = useState('Was this drawn by AI or a human?');
  const [nextActive, setNextActive] = useState(false);

  useEffect(() => {
    dispatch(fetchImages());
  }, [dispatch]);

  useEffect(() => {
    if (images.length > 0) {
      const indexes = Array.from({ length: images.length }, (_, i) => i);
      for (let i = indexes.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [indexes[i], indexes[j]] = [indexes[j], indexes[i]];
      }
      setShuffledIndexes(indexes);
    }
  }, [images]);

  const submitAnswer = (answer) => {
    if (currentIndex >= shuffledIndexes.length) return;

    const imageIndex = shuffledIndexes[currentIndex];
    const currentImage = images[imageIndex];
    const isCorrect = answer === currentImage.type;

    setResults((prevResults) => [
      ...prevResults,
      {
        image: currentImage,
        answer,
        isCorrect,
        description: currentImage.description, 
      },
    ]);

    setBorderColor(isCorrect ? css.shadowGreen : css.shadowRed);
    setImageInfo(
      `${currentImage.type}: ${currentImage.description}`
    );
    setNextActive(true);
  };

  const nextImage = () => {
    if (currentIndex + 1 < shuffledIndexes.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setBorderColor('');
      setImageInfo('Was this drawn by AI or a human?');
      setNextActive(false);
    } else {
      const lastIndex = shuffledIndexes[currentIndex];
      const lastImage = images[lastIndex];
      const lastResult = results.find((r) => r.image.id === lastImage.id);

      if (!lastResult) {
        setResults((prevResults) => [
          ...prevResults,
          {
            image: lastImage,
            answer: borderColor === css.shadowGreen ? 'AI' : 'Real',
            isCorrect:
              borderColor === css.shadowGreen
                ? lastImage.type === 'AI'
                : lastImage.type === 'Real',
          },
        ]);
      }

      navigate('/results', { state: { results } });
    }
  };

  if (status === 'loading') {
    return <div className={css.container}>Loading...</div>;
  }

  if (status === 'failed') {
    return (
      <div className={css.container}>
        Error data loading: {error}
      </div>
    );
  }

  if (!images || images.length === 0 || shuffledIndexes.length === 0) {
    return <div className={css.container}>Error. No images</div>;
  }

  const currentImage = images[shuffledIndexes[currentIndex]];
  if (!currentImage) {
    return <div className={css.container}>Image not found.</div>;
  }

  return (
    <div className={css.container}>
      <div className={css.imageContainer}>
        <img
          className={`${css.image} ${borderColor}`}
          src={currentImage.src}
          alt="current"
        />
      </div>
      <p className={css.imageInfo}>{imageInfo || ' '}</p>
      <div className={css.progressBarContainer}>
        <div className={css.progressBar}>
          <div
            className={css.progress}
            style={{
              width: `${((currentIndex + 1) / shuffledIndexes.length) * 100}%`,
            }}
          ></div>
        </div>
        <p className={css.counter} style={{ textAlign: 'right' }}>
          {currentIndex + 1} / {shuffledIndexes.length}
        </p>
      </div>
      <div className={css.buttons}>
        <button
          className={borderColor !== '' ? css.inactive : ''}
          disabled={borderColor !== ''}
          onClick={() => submitAnswer('AI')}
        >
        AI
        </button>
        <button
          className={borderColor !== '' ? css.inactive : ''}
          disabled={borderColor !== ''}
          onClick={() => submitAnswer('Real')}
        >
        Human
        </button>
        <button
          className={!nextActive ? css.inactive : ''}
          onClick={nextImage}
          disabled={!nextActive}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TestPage;
